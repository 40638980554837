import React from "react"
import LogoFull from "../../images/logo.svg"
import LogoSimplified from "../../images/logo_simplified_2.svg"

const LogosSection = () => (
<section id="Logos" className="guide wrapper">
<div className="padded wrapper">
  <h1> Logos</h1>
  <div className="wrapper">
    <p>The logo is provided as a vector image in two differents variants: <b>Full</b> and <b>Simplified</b>. The simplified variant is meant only for placement where the space is scarce and the text would not be legible at all. For this reason the text is omitted. The full logo is meant for all other placements.</p>

  <h2>Do</h2>
    <p>The primary use cases are in high contrast on grey or on a light background. Since the logos use a cropping technique, they can be used on other pictures (eg. Forest photography). This should be used carefully!</p>
  </div>

  <div className="block50 wrapper">
    <h2 className="row">On dark background</h2>
    <div className="images block25 card bg-dark-gray">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>

  <div className="block50 wrapper">
    <h2 className="row">On light background</h2>
    <div className="images block25 card">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>

  <div className="block50 wrapper">
    <h2 className="row">On image background</h2>
    <div className="images block25 card img-bg">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>
  <div className="row wrapper">
    <h2>Don't</h2>
    <p className="row">Placing the logos on greens, reds and blues should be avoided, since this could create a Lego-like idea of the brand.</p>
  </div>
  <div className="block50 wrapper">
    <div className="images block25 card bg-green">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>
  <div className="block50 wrapper">
    <div className="images block25 card bg-blue">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>
  <div className="block50 wrapper">
    <div className="images block25 card bg-red">
      <img src={LogoFull} alt="Rundholzportal Logo"></img>
      <img src={LogoSimplified} alt="Rundholzportal Logo"></img>
    </div>
  </div>
</div>
</section>
)

export default LogosSection
